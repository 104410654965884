import { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import DateInput from "../../DateInput";
import TimeItem from "../TimeItem";
import Bin from "../../../icons/Bin";
import {
  DateWrapper,
  InputWrapper,
  Pick,
  Label,
  PickTitile,
  DatesWrapper,
  HoursWrapper,
  Controls,
  TimesWrapper,
  Delete,
} from "./DateItem.styles";
import useDates from "../../../store/useTimes";

const DateItem = ({
  excludedDates,
  name,
  shape,
  handleChange,
  removeDate,
  isOpen,
  startDate,
  endDate,
  cardId,
  dateId,
  hasDelete,
}) => {
  const { t } = useTranslation();
  const endDateRef = useRef(null);
  const { times, createTimes, handleTimeChange, showErros } = useDates();

  useEffect(() => {
    createTimes(startDate, endDate, name, shape, cardId, dateId);
  }, [startDate, endDate, name, shape, cardId, dateId, createTimes]);

  const filteredTimes = times.filter(
    (time) => time.roomId === cardId && time.dateId === dateId
  );

  const excludedDatesWithAllDays = () => {
    const year = new Date().getFullYear();
    const newExcludedDates = [...excludedDates];
    times.forEach((time) => {
      if (
        time.roomId !== cardId &&
        time.name === name &&
        time.allDay === true
      ) {
        const parsedDay = time.day.split("/");
        const [day, month] = parsedDay;
        newExcludedDates.push(new Date(`${month}/${day}/${year}`));
      }
    });
    return newExcludedDates;
  };

  return (
    <DateWrapper>
      <Pick>
        {isOpen && <PickTitile>{t("pcikDate")}</PickTitile>}
        <DatesWrapper>
          <InputWrapper>
            {isOpen && <Label as="span">{t("from")}</Label>}
            <DatePicker
              selected={startDate}
              onChange={(value, event) => {
                event.target = { value, name: "startDate" };
                handleChange(cardId, dateId, event);
                endDateRef.current.setOpen(true);
              }}
              selectsStart
              startDate={startDate}
              excludeDates={excludedDatesWithAllDays()}
              endDate={endDate}
              customInput={
                <DateInput
                  aria-label={`startDate-${name.replace(" ", "-")}-${dateId}`}
                />
              }
              minDate={new Date()}
            />
          </InputWrapper>
          <InputWrapper>
            {isOpen && <Label as="span">{t("to")}</Label>}
            <DatePicker
              popperClassName="z-20"
              ref={endDateRef}
              selected={endDate}
              onChange={(value, event) => {
                event.target = { value, name: "endDate" };
                handleChange(cardId, dateId, event);
              }}
              excludeDates={excludedDatesWithAllDays()}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              customInput={
                <DateInput
                  aria-label={`endDate-${name.replace(" ", "-")}-${dateId}`}
                />
              }
              minDate={startDate}
            />
          </InputWrapper>
        </DatesWrapper>
      </Pick>
      <Pick>
        {isOpen && (
          <PickTitile className="lg:ml-[5rem]">{t("ChooseHour")}</PickTitile>
        )}
        {isOpen && (
          <HoursWrapper className="ml-3 lg:ml-[5rem]">
            <Label as="span">{t("from")}</Label>
            <Label as="span">{t("to")}</Label>
          </HoursWrapper>
        )}
        <TimesWrapper>
          {filteredTimes?.length > 0 &&
            filteredTimes.map((time) => (
              <TimeItem
                key={cardId + dateId + time.day}
                name={time.name}
                shape={time.shape}
                startHour={time.startHour}
                endHour={time.endHour}
                day={time.day}
                allDay={time.allDay}
                error={time.error}
                showErros={showErros}
                handleChange={(e) =>
                  handleTimeChange(e, cardId, dateId, time.day)
                }
              />
            ))}
        </TimesWrapper>
      </Pick>
      <Controls>
        {hasDelete && (
          <Delete type="button" onClick={() => removeDate(cardId, dateId)}>
            <Bin />
          </Delete>
        )}
      </Controls>
    </DateWrapper>
  );
};

export default DateItem;
