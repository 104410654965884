import tw, { styled } from "twin.macro";

export const Wrapper = tw.div`
  relative w-full flex flex-col items-start justify-center gap-4
  border border-[#E1E1E1] rounded-xl uppercase text-xl pb-[6rem]
  md:p-12 md:gap-6
`;
export const Row = tw.div`
  w-full flex flex-col gap-6 justify-start pt-4 px-4  
  md:flex-row md:gap-8
`;

export const LodgingColumn = tw.div`
    flex flex-col justify-start gap-2
    flex-grow  md:gap-7
`;

export const Column = tw.div`
    flex flex-col justify-start gap-2
    md:gap-[2.4rem] 
`;

export const Infos = tw.div`
 w-[96%] mx-auto md:m-0 flex flex-col gap-2 justify-start items-start 
 rounded-2xl bg-[#F8F8F8] border-[1px] border-[#DADADA] p-2
 md:flex-row md:gap-7 md:max-h-[10.56rem] md:w-[90%] md:p-4 
`;

export const RoomImage = tw.img`
 w-full object-cover flex-shrink-0 rounded-2xl
 md:w-[8.375rem] md:h-[8.375rem]
`;

export const Description = tw.div`
  text-base first-letter:capitalize lowercase
`;

export const RemoveButton = tw.button`
     self-end flex justify-center items-center gap-2 px-4 py-2
     bg-[#FBEEEE] text-[#C32E2E] uppercase rounded-lg
`;

export const Amount = styled.div`
  ${tw`flex flex-row justify-start gap-2 uppercase 
        md:flex-col md:gap-6 md:leading-[1.5rem]`}
  input {
    ${tw`inline-flex justify-between items-stretch  text-center w-40 h-14
    border rounded-lg border-[#666666] focus:outline-none
    lg:w-28`};
    ::-webkit-textfield-decoration-container {
      align-self: stretch !important;
      height: 3.5rem;
    }
    ::-webkit-inner-spin-button {
      opacity: 1;
    }
  }
`;

export const ReadMoreBtn = tw.button`
  font-bold capitalize 
`;
