import { useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
//components
import { ToastContainer, toast } from "react-toastify";
import ProgressBar from "../ProgressBar";
import LangSwitcher from "../LangSwitcher";
import Footer from "../Footer";
import NotFound from "../../pages/NotFound";
//styles
import { Header, Logo, Wrapper } from "./Layout.styles";
//store
import useSelected from "../../store/useSelected";
import usePackages from "../../store/usePackages";
import useLodging from "../../store/useLodging";
import useTimes from "../../store/useTimes";
import { EMAIL_URL, PLACE_URL } from "../../lib/constants";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import useInfo from "../../store/useInfo";
import fetchit from "../../lib/fetchit";
import { useTranslation } from "react-i18next";

function Layout() {
  const [progressbarSwiper, setProgressbarSwiper] = useState();
  const { selected, updateSelected } = useSelected();
  const { addPackages } = usePackages();
  const { times, setShowErros, showErros } = useTimes();
  const { updateLodging, lodging } = useLodging();
  const { info } = useInfo();
  const { t } = useTranslation();
  //get the step
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const step = parseInt(location.pathname.split("/").slice(-1)[0]);

  // fetch hotel info
  const fetchInfo = async () => {
    const res = await fetch(PLACE_URL + id);
    if (!res.ok) {
      throw new Error("Network response was not ok");
    }
    return res.json();
  };
  const { data, isLoading, isError } = useQuery(["place"], fetchInfo);

  if (isLoading || isError) return <NotFound isError={isError} />;
  //functio to get if the next button is disabled or not
  const isDisabled = () => {
    let disabled = true;
    if (step === 1 && selected.length > 0) {
      disabled = false;
    }
    if (step === 2) {
      const allSelectedHasDates = selected.every(
        (card) =>
          card.dates.length > 0 &&
          card.dates.every((date) => date.startDate !== null)
      );
      const allTimesHasNoErrors = times.every((time) => time.error === null);
      disabled = !allSelectedHasDates || (showErros && !allTimesHasNoErrors);
    } else if (step !== 1) {
      disabled = false;
    }

    return disabled;
  };

  //handle navigations next/prev and book again
  const nextStep = () => {
    const notError = times.every((time) => time.error === null);
    if (step < 6 && notError) {
      const path = `/${id}/${parseInt(step) + 1}`;
      progressbarSwiper?.slideNext();
      navigate(path);
    }
    if (step === 2 && !notError) {
      setShowErros(true);
      toast(t("datesError"), {
        type: "error",
      });
    }
  };

  const previousStep = () => {
    const path = `/${id}/${step - 1}`;
    progressbarSwiper?.slidePrev();
    navigate(path);
  };

  //handle form submit and book again
  const handleSubmit = (e) => {
    e.preventDefault();
    //post Data
    const message = JSON.stringify(
      {
        "the selected days/times in each room": times,
        "the lodging": lodging,
      },
      null,
      4
    );
    fetchit(
      EMAIL_URL,
      {
        place: id,
        name: info.name,
        email: info.email,
        message: "<pre>" + message + "</pre>",
      },
      "POST"
    );
    const path = `/${id}/${parseInt(step) + 1}`;
    progressbarSwiper?.slideNext();
    navigate(path);
  };

  const handleBookAgain = () => {
    updateSelected([]);
    addPackages([]);
    updateLodging([]);
    const path = `/${id}/1`;
    progressbarSwiper?.slideTo(0);
    navigate(path);
  };
  return (
    <Wrapper onSubmit={(e) => handleSubmit(e)}>
      <Helmet htmlAttributes={{ lang: i18next.language }}>
        <title>{data?.name}</title>
        <meta
          name="description"
          content="book your next meeting place at ease"
        />
      </Helmet>
      <Header>
        <Logo src={data?.logo} />
        <LangSwitcher locales={data?.locales} />
      </Header>
      <ProgressBar
        disabled={isDisabled()}
        setProgressbarSwiper={setProgressbarSwiper}
        step={step}
      />
      {step > 1 && selected?.length === 0 ? (
        <Navigate to="1" replace />
      ) : (
        <Outlet />
      )}
      <Footer
        handleNextStep={nextStep}
        handlePreviousStep={previousStep}
        handleBookAgain={handleBookAgain}
        step={step}
        disabled={isDisabled()}
      />
      <ToastContainer
        closeButton={false}
        toastClassName="custom_toast_wrapper"
        bodyClassName="custom_toast_body"
        position="bottom-center"
      />
    </Wrapper>
  );
}

export default Layout;
