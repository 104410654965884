import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
//components
import Layout from "../components/Layout";
//pages
import Dates from "./Dates";
import FoodBeverage from "./FoodBeverage";
import Lodging from "./Lodging";
import MeetingSpace from "./MeetingSpace";
import PersonalInfo from "./PersonalInfo";
import Confirmation from "./Confirmation";
import NotFound from "./NotFound";
const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<Layout />}>
          <Route path="/:id" element={<Navigate to="1" replace />} />
          <Route path="1" element={<MeetingSpace />} />
          <Route path="2" element={<Dates />} />
          <Route path="3" element={<FoodBeverage />} />
          <Route path="4" element={<Lodging />} />
          <Route path="5" element={<PersonalInfo />} />
          <Route path="6" element={<Confirmation />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
