import { useTranslation } from "react-i18next";
import TimeInput from "../../TimeInput";
import {
  AllDay,
  Choice,
  Day,
  HoursWrapper,
  Wrapper,
  ErrorWrapper,
  Checkbox,
  ErrorMessage,
} from "./TimeItem.styles";
import ErrorIcon from "../../../icons/ErrorIcon";
import { useMemo, useState } from "react";

const TimeItem = ({
  handleChange,
  name,
  day,
  startHour,
  endHour,
  allDay,
  error,
  shape,
  showErros,
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const isEndHourHasError = useMemo(() => {
    if (showErros && !allDay) {
      if (startHour && !endHour) {
        return true;
      } else if (startHour && endHour) {
        return endHour < startHour;
      }
    } else {
      return false;
    }
  }, [showErros, startHour, endHour, allDay]);

  return (
    <Wrapper error={showErros && error}>
      <Day>{day}</Day>
      <Choice>
        <HoursWrapper>
          <TimeInput
            aria-label={`startHour-${name}-${shape}-${day}`}
            name="startHour"
            isDisabled={allDay}
            value={startHour ? startHour : ""}
            onChange={handleChange}
          />
          <TimeInput
            aria-label={`endHour-${name}-${shape}-${day}`}
            error={isEndHourHasError}
            isDisabled={allDay}
            name="endHour"
            value={endHour ? endHour : ""}
            onChange={handleChange}
          />
        </HoursWrapper>
        <AllDay isChecked={allDay}>
          <Checkbox
            name="allDay"
            type="checkbox"
            checked={allDay}
            onChange={handleChange}
            id={`allDay-${name}-${shape}-${day}`}
          />
          <label htmlFor={`allDay-${name}-${shape}-${day}`}>
            {t("allDay")}
          </label>
        </AllDay>
      </Choice>
      {showErros && error && (
        <ErrorWrapper
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered && <ErrorMessage>{error}</ErrorMessage>}
          <ErrorIcon />
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default TimeItem;
