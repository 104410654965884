import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
//styles
import "swiper/css";
import "swiper/css/pagination";
import {
  ImagesWrapper,
  Info,
  Infos,
  Wrapper,
  Image,
  Checkebox,
  Content,
  Description,
} from "./Card.styles";
import { useTranslation } from "react-i18next";

function Card({
  name,
  dimensions,
  shape,
  adultsCapcity,
  images,
  checked,
  handleClick,
}) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ImagesWrapper>
        <Swiper
          className="w-full h-full"
          slidesPerView="1"
          pagination={images?.length > 1 ? true : false}
          modules={[Pagination]}
        >
          {images?.length &&
            images.map((image) => (
              <SwiperSlide className="min-w-max" key={image.location}>
                <Image src={image.location} alt={name} />
              </SwiperSlide>
            ))}
        </Swiper>
      </ImagesWrapper>
      <Content>
        <Description>
          <h2>{name}</h2>
          <Infos>
            <Info>
              <p>{t("shape")}</p>
              <span>{shape}</span>
            </Info>
            <Info>
              <p>{t("guests")}</p>
              <span>{adultsCapcity}</span>
            </Info>
            <Info>
              <p>{t("surface")}</p>
              <span>{dimensions}</span>
            </Info>
          </Infos>
        </Description>
        <Checkebox
          aria-label={name.toLowerCase()}
          role="checkbox"
          aria-checked={checked}
          type="button"
          onClick={handleClick}
          checked={checked}
        />
      </Content>
    </Wrapper>
  );
}

Card.propTypes = {};

export default Card;
