import create from "zustand";

const useLodging = create((set) => ({
  lodging: [],
  addLodging: (newLodging) =>
    set((state) => ({ lodging: [...state.lodging, newLodging] })),
  removeLodging: (id) =>
    set((state) => ({
      lodging: state.lodging.filter((item) => item.id !== id),
    })),
  handleChange: (id, e, room) =>
    set((state) => {
      const index = state.lodging.findIndex((item) => item.id === id);
      const newArray = state.lodging;
      if (index > -1 && e) {
        newArray[index] = {
          ...newArray[index],
          [e.target.name]: e.target.value,
        };
      } else if (index > -1 && room) {
        newArray[index] = { ...newArray[index], room };
      }
      return { lodging: newArray };
    }),
}));

export default useLodging;
