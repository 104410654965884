import tw from "twin.macro";

export const Warpper = tw.div`
  relative flex flex-col justify-center items-center gap-6 mx-4
  p-3 pt-[4.375rem] border-2 border-[#E1E1E1] rounded-xl last:mb-16
  md:items-start md:p-10 md:pt-[5.3rem] md:mx-0
`;

export const RoomName = tw.h2`
  absolute top-0 left-3 border-2 border-t-0 border-[#E1E1E1] rounded-b-lg
  text-center px-6 py-4 font-medium text-lg
  md:text-2xl md:left-10
`;
