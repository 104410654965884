import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  ${tw`  w-full flex flex-col items-center gap-4 border-[#E1E1E1] 
        lg:flex-row lg:items-start lg:pb-0 lg:py-6 lg:px-6
        lg:rounded-xl lg:border-2 lg:shadow-lg
  `};
  .react-datepicker-popper {
    z-index: 10;
  }
`;

export const ImagesWrapper = tw.div`
  relative w-full h-[13rem] border border-[rgba(0, 0, 0, 0.2)] rounded-lg
  lg:w-[14.375rem] lg:h-[13.69rem]
`;

export const Image = tw.img`
    w-full h-full object-cover rounded-lg lg:w-[14.375rem]
`;

export const Shape = tw.h3`
   absolute top-0 left-6 px-2 py-3 bg-white
   text-base font-medium text-center z-20
   border border-t-0 border-[#C6C6C6] rounded-lg rounded-t-none
`;

export const Description = tw.div`
    w-full flex flex-col justify-start items-start gap-4  
    lg:gap-3 lg:ml-6 lg:px-0 
`;

export const RoomName = tw.h2`
   text-2xl font-medium leading-7
   lg:text-[2rem] lg:leading-9
`;

export const Add = tw.button`w-full flex justify-center items-center gap-1
     px-4 py-4 capitalize text-[#3396EB] text-2xl leading-7
     border-2 border-dashed border-[#3396EB] rounded-[10px]
     disabled:grayscale
`;
