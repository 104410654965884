const ErrorIcon = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.013 17.0486C20.612 15.2699 21.4979 12.9608 21.5 10.5661C21.5 5.03032 16.79 0.526367 11 0.526367C5.21 0.526367 0.5 5.03032 0.5 10.5661C0.5 16.1018 5.21 20.6058 11 20.6058H18.3958C18.7369 20.6058 19.0704 20.5044 19.3541 20.3144C19.6378 20.1244 19.8589 19.8543 19.9896 19.5383C20.1202 19.2223 20.1544 18.8745 20.0879 18.539C20.0214 18.2035 19.8572 17.8952 19.616 17.6533L19.013 17.0486ZM11 4.92579C11.2225 4.92579 11.44 4.99195 11.625 5.1159C11.81 5.23985 11.9542 5.41603 12.0394 5.62216C12.1245 5.82828 12.1468 6.0551 12.1034 6.27392C12.06 6.49274 11.9528 6.69374 11.7955 6.8515C11.6382 7.00926 11.4377 7.1167 11.2195 7.16023C11.0012 7.20376 10.775 7.18142 10.5695 7.09604C10.3639 7.01066 10.1882 6.86607 10.0646 6.68056C9.94098 6.49505 9.875 6.27696 9.875 6.05385C9.875 5.75467 9.99353 5.46774 10.2045 5.25619C10.4155 5.04464 10.7016 4.92579 11 4.92579ZM12.125 15.0783H9.875C9.67609 15.0783 9.48532 14.9991 9.34467 14.858C9.20402 14.717 9.125 14.5257 9.125 14.3263C9.125 14.1268 9.20402 13.9355 9.34467 13.7945C9.48532 13.6535 9.67609 13.5742 9.875 13.5742H10.25V9.71326C10.107 9.63048 9.99529 9.50272 9.93212 9.34977C9.86894 9.19683 9.85786 9.02726 9.90059 8.86735C9.94332 8.70745 10.0375 8.56616 10.1685 8.46538C10.2994 8.3646 10.4599 8.30997 10.625 8.30996H11C11.1989 8.30996 11.3897 8.38919 11.5303 8.53023C11.671 8.67126 11.75 8.86255 11.75 9.062V13.5742H12.125C12.3239 13.5742 12.5147 13.6535 12.6553 13.7945C12.796 13.9355 12.875 14.1268 12.875 14.3263C12.875 14.5257 12.796 14.717 12.6553 14.858C12.5147 14.9991 12.3239 15.0783 12.125 15.0783Z"
        fill="#FF6855"
      />
    </svg>
  );
};

export default ErrorIcon;
