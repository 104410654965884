import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
//Components
import CardsFilter from "../components/CardsFilter";
import Title from "../components/Title";
//Store
import useRooms from "../store/useRooms";
import { BASE_URL } from "../lib/constants";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";

export default function MeetingSpace() {
  const { rooms, setRooms } = useRooms();

  //get step
  const { t } = useTranslation();
  const { id } = useParams();

  //fetch meeting rooms
  const fetchMeetings = async () => {
    const res = await fetch(BASE_URL + id);
    return res.json();
  };
  const { isLoading, data } = useQuery(["rooms"], fetchMeetings);

  useEffect(() => {
    if (data) setRooms(data.results);
  }, [data, setRooms]);

  const meetingRooms = rooms?.filter((room) => room.type === "meeting");

  return (
    <>
      <div className=" w-full flex justify-start flex-col gap-12 lg:gap-16 lg:w-[90%] px-6 lg:p-0 mx-auto mb-[150px] ">
        <Title title={t("select1")} />
        {isLoading && <Loading />}
        {meetingRooms?.length > 0 && (
          <CardsFilter meetingRooms={meetingRooms} />
        )}
      </div>
    </>
  );
}
