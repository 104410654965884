import tw, { styled } from "twin.macro";

export const Wrapper = tw.form`
    flex flex-col items-center justify-between gap-12 lg:gap-16
`;

export const Header = tw.header`
    flex w-full justify-center items-center pt-11 px-3 gap-9
`;

export const Languages = styled.ul`
  ${tw`flex flex-shrink-0 gap-1`}
  li {
    ${tw`cursor-pointer`}
  }
`;

export const Logo = tw.img`
    w-40 h-16 md:w-60 md:h-20 object-contain
`;
