import tw, { styled } from "twin.macro";

export const StyledInput = styled.input`
  ${tw`flex justify-between gap-2
   bg-transparent border-b-[1px] border-b-black 
   font-bold text-sm leading-5  min-w-min pb-2
   `};
  ::-webkit-calendar-picker-indicator {
    margin: 0 !important;
  }
  ${({ isDisabled }) => isDisabled && tw`text-[#898989]  text-opacity-75`}
  ${({ error }) => error && tw`text-[#FF6855] border-[#FF6855]`}
`;
