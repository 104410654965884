const CalendarIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.64 1.96004H15.44V0.680039C15.44 0.328039 15.152 0.0400391 14.8 0.0400391H12.56C12.208 0.0400391 11.92 0.328039 11.92 0.680039V1.96004H8.07997V0.680039C8.07997 0.328039 7.79197 0.0400391 7.43997 0.0400391H5.19997C4.84797 0.0400391 4.55997 0.328039 4.55997 0.680039V1.96004H1.35997C1.00797 1.96004 0.719971 2.24804 0.719971 2.60004V17.32C0.719971 17.672 1.00797 17.96 1.35997 17.96H18.64C18.992 17.96 19.28 17.672 19.28 17.32V2.60004C19.28 2.24804 18.992 1.96004 18.64 1.96004ZM13.2 1.32004H14.16V3.88004H13.2V1.32004ZM5.83997 1.32004H6.79997V3.88004H5.83997V1.32004ZM4.55997 3.24004V4.52004C4.55997 4.87204 4.84797 5.16004 5.19997 5.16004H7.43997C7.79197 5.16004 8.07997 4.87204 8.07997 4.52004V3.24004H11.92V4.52004C11.92 4.87204 12.208 5.16004 12.56 5.16004H14.8C15.152 5.16004 15.44 4.87204 15.44 4.52004V3.24004H18V6.44004H1.99997V3.24004H4.55997ZM1.99997 16.68V7.72004H18V16.68H1.99997Z"
        fill="black"
      />
    </svg>
  );
};

export default CalendarIcon;
