import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//styles
import { buttonVariants, StyledButton, StyledIcon } from "./Button.styles";

function Button({
  label,
  type = "button",
  color,
  bgColor,
  Icon,
  border,
  handleClick,
  variant,
  disabled,
}) {
  const { t } = useTranslation();
  return (
    <StyledButton
      type={type}
      color={color}
      bgColor={bgColor}
      border={border}
      variant={variant}
      onClick={handleClick}
      disabled={disabled}
    >
      {t(label)}{" "}
      <StyledIcon>
        <Icon color={color} />
      </StyledIcon>{" "}
    </StyledButton>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  border: PropTypes.bool,
  handleClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.keys(buttonVariants)),
};

export default Button;
