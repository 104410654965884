import tw, { styled } from "twin.macro";

export const Wrapper = tw.div`
  w-full flex flex-col lg:flex-row gap-12 lg:gap-[6.5rem] items-center shadow-lg rounded-xl p-0 lg:pr-[4rem] 
  bg-white
`;

export const ImagesWrapper = tw.div`
  w-full lg:w-[21.375rem]  h-[16.44rem] 
`;

export const Image = tw.img`
  object-cover w-full lg:w-[21.375rem] h-[16.44rem] rounded-xl
`;

export const Content = tw.div`
  w-full flex flex-row justify-between px-6 pb-8  lg:p-0
`;

export const Description = tw.div`
    flex flex-col justify-start lg:justify-between items-start gap-10 font-medium text-3xl
`;
export const Infos = tw.div`
    flex justify-start lg:justify-center items-center gap-12 flex-wrap
`;

export const Info = styled.div`
  ${tw`flex flex-col justify-start items-center text-2xl font-medium capitalize`};
  span {
    ${tw`text-lg text-[#B2BBC2] font-normal`}
  }
`;

export const Checkebox = styled.button(({ checked }) => [
  tw`flex-shrink-0 appearance-none relative w-[3.75rem] h-[3.75rem] border border-[#CBCBCB] rounded-xl`,
  checked &&
    tw`border-0 before:content['✓'] before:absolute before:inset-0 
    before:bg-blue-500 before:text-white before:text-2xl before:rounded-xl
    before:flex before:items-center before:justify-center
    `,
]);
