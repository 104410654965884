import tw from "twin.macro";

export const Wrapper = tw.section`
    flex flex-col gap-6 bg-[#F6F6F6] p-6 lg:p-8
    border border-[#DCDCDC] rounded-2xl
`;

export const Shape = tw.h2`
  text-3xl capitalize text-[#0D2A3F]
`;

export const List = tw.div`
  w-full flex flex-col gap-4
`;
