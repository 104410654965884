import { v4 as uuidv4 } from "uuid";
//components
import LodgingCard from "../components/LodgingCard";
import Title from "../components/Title";
//store
import useLodging from "../store/useLodging";
import useRooms from "../store/useRooms";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export default function Lodging() {
  const { t } = useTranslation();
  const { lodging, addLodging, handleChange } = useLodging();
  const rooms = useRooms((state) => state.rooms);

  const filtredRooms = useMemo(
    () => rooms?.filter((room) => room.type !== "meeting"),
    [rooms]
  );

  const handleClick = () => {
    addLodging({
      id: uuidv4(),
      room: "",
      startDate: null,
      endDate: null,
      amount: 0,
    });
  };

  return (
    <>
      <div className="w-full lg:w-[90%] p-8 lg:p-0 flex flex-col gap-10 mx-auto mb-[120px]">
        <Title title={t("select4")} />
        {lodging?.length > 0 &&
          lodging.map((item) => (
            <LodgingCard
              key={item.id}
              id={item.id}
              startDate={item.startDate}
              endDate={item.endDate}
              rooms={filtredRooms}
              room={item.room}
              handleChange={handleChange}
            />
          ))}
        <button
          type="button"
          className="flex gap-2 uppercase self-start text-xl text-blue-500"
          onClick={handleClick}
        >
          + {t("newLodging")}
        </button>
      </div>
    </>
  );
}
