import { useTranslation } from "react-i18next";
import Select from "../../Select";
import {
  AdditonalInfo,
  Column,
  Delete,
  Label,
  PackageSelect,
  Pdf,
  Wrapper,
} from "./PackageItem.styles";
import { Player } from "@lottiefiles/react-lottie-player";
import pdf from "../../../lotties/pdf.json";
import Bin from "../../../icons/Bin";
import { useEffect, useState } from "react";
import { BASE_CDN } from "../../../lib/constants";

const PackageItem = ({
  isOpen,
  cardId,
  name,
  day,
  time,
  packagesOptions,
  pack,
  handleChange,
  removePackage,
}) => {
  const [selected, setSelected] = useState(pack);

  useEffect(() => {
    handleChange(null, cardId, day, pack?.id, selected);
  }, [selected, setSelected, cardId, day, pack?.id, handleChange]);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PackageSelect>
        {isOpen && <Label>{t("package")}</Label>}
        <Select
          selected={selected}
          setSelected={setSelected}
          options={packagesOptions}
        />
      </PackageSelect>
      <AdditonalInfo>
        <Column>
          {isOpen && (
            <Label htmlFor={`${name}-guests-at-${day + time}`}>
              {t("guests")}:
            </Label>
          )}
          <input
            className="inline-block"
            id={`${name}-guests-at-${day + time}`}
            type="number"
            name="guests"
            min="0"
            value={pack.guests}
            onChange={(e) => handleChange(e, cardId, day, pack?.id)}
          />
        </Column>
        {pack?.pdf && (
          <Column>
            {isOpen && <Label as="p">{t("preview")}</Label>}
            <Pdf href={BASE_CDN + pack?.pdf} download={pack?.name}>
              <Player
                key={crypto.randomUUID()}
                keepLastFrame
                autoplay
                loop={false}
                src={pdf}
              />
            </Pdf>
          </Column>
        )}
        {!isOpen && (
          <Delete
            type="button"
            onClick={() => removePackage(cardId, day, pack?.id)}
          >
            <Bin />
          </Delete>
        )}
      </AdditonalInfo>
    </Wrapper>
  );
};

export default PackageItem;
