import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { ListItem } from "./LangSwitcher.styles";

function LangSwitcher({ locales }) {
  const { t } = useTranslation();
  const changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex w-full justify-between items-center gap-2 rounded-full bg-white border border-black  px-4 py-2 text-base font-medium  ">
        {t(i18next.language)}
        <span>
          <ChevronDownIcon width="20" height="20" />
        </span>
      </Menu.Button>
      <Menu.Items
        className="absolute z-20 right-0 left-0 flex flex-col gap-2 py-4 justify-center items-center sm:left-[0.5px] mt-2 origin-top-right 
      divide-y divide-gray-100 rounded-md bg-white ring-black shadow-2xl ring-opacity-5 focus:outline-none"
      >
        {locales?.map((lang) => (
          <Menu.Item key={lang}>
            {({ active }) => (
              <ListItem onClick={() => changeLanguage(lang)} active={active}>
                {t(lang)}
              </ListItem>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}

export default LangSwitcher;
