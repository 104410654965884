import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import notFound from "../lotties/notFoud.json";

export default function NotFound({ isError }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full p-6 gap-5 pt-8 flex flex-col justify-around items-center text-centre pb-[150px] ">
        <Player autoplay loop={true} src={notFound} />
        {isError && <p className="text-2xl font-medium">{t("notFound")}</p>}
      </div>
    </>
  );
}
