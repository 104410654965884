import React from "react";
import { StyledInput } from "./Input.styles";
import CalendarIcon from "../../icons/CalenderIcon";

const DateInput = React.forwardRef(({ value, ...props }, ref) => {
  return (
    <StyledInput ref={ref} role="date" type="button" {...props}>
      {value ? value : "DD/MM/YYYY"}{" "}
      <span>
        <CalendarIcon />
      </span>
    </StyledInput>
  );
});

export default DateInput;
