import tw from "twin.macro";

export const DateWrapper = tw.div`
   relative w-full flex flex-col items-start justify-center gap-y-6 
   border-[1px] rounded-xl border-[rgba(0, 0, 0, 0.2)] bg-[rgba(217, 217, 217, 0.2)] p-6
   lg:flex-row lg:justify-start 
`;

export const Pick = tw.div`
  w-full flex flex-col items-start justify-center gap-2
  md:lg:ml-12 md:first:ml-0 
`;

export const PickTitile = tw.h3`
   text-xl font-medium
`;

export const DatesWrapper = tw.div`
 w-full flex flex-col items-start justify-center gap-4
lg:flex-row lg:justify-start lg:gap-6
`;

export const HoursWrapper = tw.div`
  w-full flex items-start justify-start gap-11
  lg:flex-row lg:justify-start lg:gap-12`;

export const InputWrapper = tw.div`
   w-full flex flex-col justify-center items-start gap-3
   lg:gap-4
`;

export const Label = tw.label`
   opacity-50 capitalize text-base
`;

export const TimesWrapper = tw.div`
  w-full flex flex-col gap-1
`;

export const Controls = tw.div`
 self-end w-full flex justify-between items-center gap-8
 md:justify-start md:ml-7
`;

export const Delete = tw.button`
   absolute -top-4 -right-4  w-9 h-9 rounded-full flex justify-center items-center
   bg-white  border-[1px] border-[#DDDDDD]
`;
