import PropTypes from "prop-types";
import {
  Add,
  Datee,
  DateInfo,
  Header,
  PackagesWrapper,
  Time,
  Title,
  Wrapper,
} from "./PackageCard.styles";
import { useTranslation } from "react-i18next";
import usePackages from "../../store/usePackages";
import Plus from "../../icons/Plus";
import PackageItem from "./PackageItem";

function PackageCard({
  day,
  startHour,
  endHour,
  allDay,
  name,
  cardId,
  packages,
  addPackage,
  handleChange,
  removePackage,
}) {
  const { t } = useTranslation();
  const { packagesOptions } = usePackages();

  const getIsAddDisabled = () => {
    const lastPackage = packages[packages.length - 1];
    return lastPackage.name ? false : true;
  };
  const date = new Date();
  return (
    <Wrapper data-testid="package-card">
      <Header>
        <Title>{name}</Title>
        <DateInfo>
          <Datee>
            {t("day")}: {day + "/" + date.getFullYear()}
          </Datee>
          <Time>
            {" "}
            {allDay
              ? t("allDay")
              : `${t("time")}: ${startHour} ${t("to")} ${endHour}`}
          </Time>
        </DateInfo>
      </Header>
      <PackagesWrapper>
        {packages?.map((item, index) => (
          <PackageItem
            isOpen={packages.length - 1 === index}
            key={item.id}
            cardId={cardId}
            name={name}
            day={day}
            packagesOptions={packagesOptions}
            pack={item}
            handleChange={handleChange}
            removePackage={removePackage}
          />
        ))}
        <Add
          type="button"
          onClick={() => addPackage(cardId, day)}
          disabled={getIsAddDisabled()}
        >
          <Plus />
          {t("addNewPackage")}
        </Add>
      </PackagesWrapper>
    </Wrapper>
  );
}

PackageCard.propTypes = {
  day: PropTypes.string,
  name: PropTypes.string,
  guests: PropTypes.string,
  pack: PropTypes.array,
};

export default PackageCard;
