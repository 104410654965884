import tw, { styled } from "twin.macro";

export const CategoriesList = styled.nav(({ isFixed }) => [
  tw`flex gap-8 whitespace-nowrap  overflow-x-auto `,
  isFixed &&
    tw`z-10 fixed left-0 right-0 top-0 py-7 pl-[4%]
      bg-white shadow-[0px 4px 132px rgba(0, 0, 0, 0.11)]`,
]);

export const CategoryTag = styled.a(({ active }) => [
  tw`inline-flex items-center max-w-max cursor-pointer text-xl text-[#0D2A3F] opacity-30 uppercase 
     transition-all duration-300`,

  //Active styles
  active &&
    tw`pb-2 border-b-2 border-blue-500 font-medium text-black opacity-100`,
]);
