import { useTranslation } from "react-i18next";
import InputsGrid from "../components/InputsGrid";

//components
import Title from "../components/Title";
//store

export default function Lodging() {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full lg:w-[70%] p-8 lg:p-0 flex flex-col gap-10 mx-auto mb-[150px]">
        <Title title={t("select5")} />
        <InputsGrid />
      </div>
    </>
  );
}
