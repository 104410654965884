import PropTypes from "prop-types";
//components
import Button from "../Button";
//styles
import { Wrapper } from "./Footer.styles";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

function Footer({
  step,
  handleNextStep,
  handlePreviousStep,
  handleSubmit,
  handleBookAgain,
  disabled,
}) {
  const { t } = useTranslation();
  const confirmationStep = 6;
  const lastStep = 5;

  return (
    <Wrapper>
      {step > 1 && step !== confirmationStep && (
        <Button
          label={t("previous")}
          Icon={ArrowLeftIcon}
          variant="secondary"
          handleClick={handlePreviousStep}
        />
      )}
      {step < lastStep && (
        <Button
          label={t("next")}
          Icon={ArrowRightIcon}
          variant="primary"
          handleClick={handleNextStep}
          disabled={disabled}
        />
      )}
      {step === lastStep && (
        <Button
          type="submit"
          label={t("submit")}
          Icon={ArrowRightIcon}
          variant="primary"
          disabled={disabled}
        />
      )}
      {step === confirmationStep && (
        <Button
          label={t("bookAgain")}
          Icon={ArrowPathIcon}
          variant="primary"
          handleClick={handleBookAgain}
          disabled={disabled}
        />
      )}
    </Wrapper>
  );
}

Footer.propTypes = {
  step: PropTypes.number,
  handleNextStep: PropTypes.func,
  handlePreviousStep: PropTypes.func,
};

export default Footer;
