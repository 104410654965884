import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

//styes
import "swiper/css";
import { Step } from "./ProgressBar.styles";

function ProgressBar({ step, disabled, setProgressbarSwiper }) {
  const steps = [1, 2, 3, 4, 5];
  const { t } = useTranslation();
  return (
    <Swiper
      className="progressbar w-full h-full"
      onSwiper={(swiper) => setProgressbarSwiper(swiper)}
      modules={[Navigation]}
      navigation
      slidesPerView="auto"
      slidesOffsetAfter={0}
      breakpoints={{
        320: {
          centeredSlides: true,
          spaceBetween: 10,
        },
        640: {
          centeredSlides: true,
          spaceBetween: 80,
        },
        1020: {
          spaceBetween: 120,
        },
      }}
    >
      {steps?.length > 0 &&
        steps.map((_step) => (
          <SwiperSlide className="progressBar" key={_step}>
            <Step
              disabled={_step > step ? disabled : false}
              active={!!(_step === step)}
            >
              <NavLink to={_step.toString()}>{`0${_step} - ${t(
                "step" + _step
              )}`}</NavLink>
            </Step>
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

ProgressBar.propTypes = {
  step: PropTypes.number,
};

export default ProgressBar;
