import create from "zustand";

const useInfo = create((set) => ({
  info: {
    name: "",
    company: "",
    email: "",
    phone: "",
  },
  handleChange: (e) =>
    set((state) => ({
      info: { ...state.info, [e.target.name]: e.target.value },
    })),
}));

export default useInfo;
