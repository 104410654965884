import create from "zustand";
import { eachDayOfInterval, format } from "date-fns";

const getError = (startTime, endTime, allDay, roomName, shape, day, times) => {
  let error;

  if (!allDay && startTime === "" && endTime === "") {
    error = "nothingFilled";
    if (error) return error;
  }
  if (allDay && !startTime && !endTime) {
    error = null;
  }
  if (startTime && !allDay) {
    error = endTime === "" ? "endHourNotFilled" : null;
    if (error) return error;
  }

  if (startTime && endTime && !allDay) {
    error = endTime <= startTime ? "endHourLessThanStart" : null;
    if (error) return error;
  }

  if (times && roomName && day) {
    // eslint-disable-next-line
    const timeIsWithinExistingRange = times.some((time) => {
      if (time.name === roomName && time.day === day && time.shape !== shape) {
        if (startTime && endTime && !allDay) {
          return startTime >= time.startHour && startTime <= time.endHour;
        } else if (allDay) {
          return true;
        }
      } else {
        return false;
      }
    });

    const otherTimesAreWithinThisRange = times.some((time) => {
      if (time.name === roomName && time.day === day && time.shape !== shape) {
        return time.startHour >= startTime && time.startHour <= endTime;
      } else {
        return false;
      }
    });

    error =
      timeIsWithinExistingRange || otherTimesAreWithinThisRange
        ? "collapesedTimesSameRoom"
        : null;
  }
  return error;
};

const findTimeIndex = (roomId, dateId, day, timesArr) => {
  const index = timesArr.findIndex(
    (item) =>
      item.roomId === roomId && item.dateId === dateId && item.day === day
  );

  return index;
};

const adaptPackage = (pack) => {
  return {
    name: pack.name,
    pdf: pack.media?.filter((item) => item.mimetype === "application/pdf")[0]
      ?.filename,
  };
};
const useTimes = create((set) => ({
  showErros: false,
  times: [],
  setShowErros: (value) => set({ showErros: value }),
  createTimes: (start, end, name, shape, roomId, dateId) =>
    set((state) => {
      const newTimes = state.times;
      if (start && end) {
        const datesInteravl = eachDayOfInterval({ start, end });
        const daysStrings = datesInteravl.map((date) => format(date, "dd/MM"));

        datesInteravl.forEach((date) => {
          const day = format(date, "dd/MM");

          const index = findTimeIndex(roomId, dateId, day, state.times);
          if (index === -1) {
            const time = {
              roomId,
              dateId,
              name,
              day,
              shape,
              startHour: "",
              endHour: "",
              packages: [
                {
                  id: crypto.randomUUID(),
                  name: "",
                  guests: "0",
                  pdf: null,
                },
              ],
              allDay: false,
              error: "nothingFilled",
            };
            newTimes.push(time);
          }
        });
        for (let i = newTimes.length - 1; i >= 0; i--) {
          const time = state.times[i];
          const dayExistsInNewRange = daysStrings.includes(time.day);
          if (
            !dayExistsInNewRange &&
            time.dateId === dateId &&
            time.roomId === roomId
          ) {
            newTimes.splice(i, 1);
          }
        }
      }
      return { times: newTimes };
    }),
  handleTimeChange: (e, roomId, dateId, day) =>
    set((state) => {
      const index = findTimeIndex(roomId, dateId, day, state.times);
      const newTimes = state.times;
      if (index > -1) {
        if (e.target.name === "allDay") {
          newTimes[index] = {
            ...newTimes[index],
            error: getError(
              state.times[index].startHour,
              state.times[index].endHour,
              !state.times[index].allDay,
              state.times[index].name,
              state.times[index].shape,
              state.times[index].day,
              state.times
            ),
            [e.target.name]: !state.times[index].allDay,
          };
        } else {
          newTimes[index] = {
            ...newTimes[index],
            allDay: false,
            error:
              e.target.name === "startHour"
                ? getError(
                    e.target.value,
                    state.times[index].endHour,
                    state.times[index].allDay,
                    state.times[index].name,
                    state.times[index].shape,
                    state.times[index].day,
                    state.times
                  )
                : getError(
                    state.times[index].startHour,
                    e.target.value,
                    state.times[index].allDay,
                    state.times[index].name,
                    state.times[index].shape,
                    state.times[index].day,
                    state.times
                  ),
            [e.target.name]: e.target.value,
          };
        }
      }
      return { times: newTimes };
    }),
  handlePackageChange: (e, roomId, day, packageId, pack) =>
    set((state) => {
      const newTimes = state.times;
      const timeIndex = state.times.findIndex(
        (time) => time.roomId === roomId && time.day === day
      );

      if (timeIndex > -1) {
        const packageIndex = state.times[timeIndex].packages.findIndex(
          (item) => item.id === packageId
        );
        const targetPackage = newTimes[timeIndex].packages[packageIndex];
        if (e?.target) {
          //if we have an event that means we are changing the guests input
          newTimes[timeIndex].packages[packageIndex] = {
            ...targetPackage,
            [e.target.name]: e.target.value,
          };
        } else if (pack) {
          // otherwise, we are changing a select menu
          newTimes[timeIndex].packages[packageIndex] = {
            ...targetPackage,
            ...adaptPackage(pack),
          };
        }
      }
      return { times: newTimes };
    }),
  addPackage: (roomId, day) =>
    set((state) => {
      const timeIndex = state.times.findIndex(
        (time) => time.roomId === roomId && time.day === day
      );
      const newTimes = state.times;
      if (timeIndex > -1) {
        newTimes[timeIndex].packages = [
          ...newTimes[timeIndex].packages,
          { id: crypto.randomUUID(), package: "", guests: "0" },
        ];
      }
      return { times: newTimes };
    }),
  removePackage: (roomId, day, packageId) =>
    set((state) => {
      const timeIndex = state.times.findIndex(
        (time) => time.roomId === roomId && time.day === day
      );
      const newTimes = state.times;
      if (timeIndex > -1) {
        newTimes[timeIndex].packages = newTimes[timeIndex].packages.filter(
          (pack) => pack.id !== packageId
        );
      }
      return { times: newTimes };
    }),
}));

export default useTimes;
