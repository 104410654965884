import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import animatedConfirm from "../lotties/confirmation.json";

export default function Confirmation() {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full p-6 gap-5 pt-8 flex flex-col justify-around items-center text-centre pb-[150px] ">
        <Player
          className="w-40 h-40"
          keepLastFrame
          autoplay
          loop={false}
          src={animatedConfirm}
        />
        <p className="text-2xl font-medium">{t("confirmationMessage")}</p>
      </div>
    </>
  );
}
