import DateCard from "../DateCard";
import { RoomName, Warpper } from "./DatesList.styles";

const DatesList = ({ rooms, name, handleChange, removeDate, addDate }) => {
  return (
    <Warpper>
      <RoomName>{name}</RoomName>
      {rooms?.length > 0 &&
        rooms.map((card) => (
          <DateCard
            key={card._id}
            cardId={card._id}
            images={card.media}
            name={card.name}
            dates={card.dates}
            shape={card.shape}
            handleChange={handleChange}
            removeDate={removeDate}
            addDate={addDate}
          />
        ))}
    </Warpper>
  );
};

export default DatesList;
