import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { BASE_CDN } from "../../lib/constants";
//components
import Select from "../Select";
import DateInput from "../DateInput";
//styles
import {
  Amount,
  Column,
  Description,
  Infos,
  RemoveButton,
  RoomImage,
  Row,
  Wrapper,
  ReadMoreBtn,
  LodgingColumn,
} from "./LodgingCard.styles";
import Bin from "../../icons/Bin";
//store
import useLodging from "../../store/useLodging";

function LodgingCard({
  rooms,
  id,
  startDate,
  endDate,
  handleChange,
  amount,
  room,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(room ? room : rooms[0]);
  const [isLarge, setIsLarge] = useState(false);
  const getShortText = (string) => {
    return string?.split(" ").slice(0, 80).join(" ");
  };
  const checkLarge = (string) => {
    if (string.length > 80) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    handleChange(id, null, selected);
  }, [selected, handleChange, id]);
  const removeLodging = useLodging((state) => state.removeLodging);
  const endDateRef = useRef(null);

  const handleCalendarClose = () => {
    endDateRef.current.setOpen(true);
  };

  return (
    <Wrapper>
      <Row data-testid="lodging-card">
        <LodgingColumn>
          <p>{t("lodging")}:</p>
          <Select
            selected={selected}
            setSelected={setSelected}
            options={rooms}
          />
        </LodgingColumn>
        <Column>
          <p>{t("dateFrom")}:</p>
          <DatePicker
            selected={startDate}
            onChange={(value, event) => {
              event.target = { value, name: "startDate" };
              handleChange(id, event);
            }}
            customInput={<DateInput ariaLabel={t("dateFrom")} />}
            minDate={new Date()}
            onCalendarClose={handleCalendarClose}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </Column>
        <Column>
          <p>{t("dateTo")}:</p>
          <DatePicker
            ref={endDateRef}
            selected={endDate}
            onChange={(value, event) => {
              event.target = { value, name: "endDate" };
              handleChange(id, event);
            }}
            customInput={<DateInput ariaLabel={t("dateTo")} />}
            minDate={startDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
          />
        </Column>
        <Amount>
          <label htmlFor={"amount" + id}>{t("amount")}:</label>
          <input
            className="inline-block"
            type="number"
            id={"amount" + id}
            name="amount"
            min="0"
            value={amount}
            onChange={(e) => {
              handleChange(id, e);
            }}
          />
        </Amount>
      </Row>
      <Row>
        <Infos>
          <RoomImage src={BASE_CDN + selected?.media[0]?.filename} />

          <Description>
            {isLarge
              ? selected?.description
              : getShortText(selected?.description)}{" "}
            {checkLarge(selected?.description) && (
              <ReadMoreBtn
                type="button"
                onClick={() => setIsLarge((prev) => !prev)}
              >
                {isLarge ? t("showLess") : " ... " + t("showMore")}
              </ReadMoreBtn>
            )}
          </Description>
        </Infos>
        <RemoveButton type="button" onClick={() => removeLodging(id)}>
          <Bin />
          {t("remove")}
        </RemoveButton>
      </Row>
    </Wrapper>
  );
}

LodgingCard.propTypes = {
  id: PropTypes.string,
  rooms: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  amount: PropTypes.string,
  handleChange: PropTypes.func,
};

export default LodgingCard;
