import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { CategoriesList, CategoryTag } from "./FilterBar.styles";

function FilterBar({ categories, active, handleActive }) {
  const [isFixed, setIsFixed] = useState(false);
  const [navPosition, setNavPosition] = useState(0);
  const { ref } = useInView({
    threshold: 1,
    onChange: (inView) => {
      if (!inView) {
        setIsFixed(true);
        setNavPosition(window.scrollY);
      }
    },
  });

  useEffect(() => {
    const test = () => {
      const windowsScroll = window.scrollY;
      if (windowsScroll < navPosition) {
        setIsFixed(false);
      }
    };

    document.addEventListener("scroll", test);
  }, [navPosition]);

  return (
    <CategoriesList ref={ref} isFixed={isFixed}>
      {categories?.length > 0 &&
        categories.map((category) => (
          <CategoryTag
            href={`#${category.shape}`}
            key={category.shape}
            active={!!(active === category.shape)}
            onClick={() => handleActive(category.shape)}
          >
            <p>{category.shape}</p>
            <span>{`(${category.quantity})`}</span>
          </CategoryTag>
        ))}
    </CategoriesList>
  );
}

FilterBar.propTypes = {
  active: PropTypes.string,
  handleActive: PropTypes.func,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      qunatity: PropTypes.number,
    })
  ),
};

export default FilterBar;
