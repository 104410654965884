import PropTypes from "prop-types";
import { StyledTitle } from "./Title.styles";

function Title({ title }) {
  return <StyledTitle>{title}</StyledTitle>;
}

Title.propTypes = {
  title: PropTypes.string,
};

export default Title;
