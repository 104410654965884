import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(({ error }) => [
  tw`relative flex flex-col items-center gap-3 bg-white rounded-xl 
    lg:flex-row lg:gap-4  lg:px-1.5`,
  error && tw`border border-[#FF6855]`,
]);

export const Day = styled.div`
  ${tw`relative w-full text-sm font-semibold text-center py-2  
       lg:py-0 lg:mr-4`}
  &:after {
    content: "";
    ${tw`bg-[#007ADF] absolute bottom-0 w-full h-[1px] right-0
       lg:w-[1px] lg:h-9 lg:top-1/2 lg:-translate-y-1/2 lg:-right-4`};
  }
`;

export const Choice = tw.div`
    w-full flex justify-between items-center gap-4 pb-3 px-3
    lg:gap-6 lg:py-1.5 lg:pr-1.5 lg:px-0
`;

export const HoursWrapper = tw.div` 
  w-full flex items-start justify-start gap-4 
  `;

export const AllDay = styled.div(({ isChecked }) => [
  tw`flex justify-center items-center gap-1.5 px-2 py-2 rounded-md cursor-pointer [&>label, input]:cursor-pointer
       bg-white text-sm font-medium capitalize border border-[#DDDDDD]
       md:px-6`,
  isChecked && tw`border-[#3396EB]`,
]);

export const Checkbox = styled.input`
  appearance: none;
  ${tw`w-2.5 h-2.5 border border-[#ADADAD] rounded-full`}
  &:checked {
    ${tw`border-0 bg-[#3396EB]`}
  }
`;

export const ErrorWrapper = tw.span`
 absolute left-3.5 top-1.5 z-20 cursor-pointer
 md:left-[calc(100% + 0.5rem)] md:top-1/2 md:-translate-y-1/2
`;

export const ErrorMessage = tw.span`
  absolute z-20 -top-full -translate-y-1/2 right-full text-center px-3.5 py-2.5
  bg-[#FFF1F0] border border-[#FF6855] rounded-lg text-[#FF6855] text-base 
  box-shadow[0px 4px 14px rgba(0, 0, 0, 0.25)] 
`;
