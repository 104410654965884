import PropTypes from "prop-types";
import useTimes from "../../store/useTimes";
import PackageCard from "../PackageCard";
import { List } from "./TimesList.styles";

function TimesList() {
  const { times, handlePackageChange, addPackage, removePackage } = useTimes();

  return (
    <List>
      {times?.length > 0 &&
        times.map((time) => (
          <PackageCard
            key={time.day + time.roomId}
            cardId={time.roomId}
            name={time.name}
            day={time.day}
            allDay={time.allDay}
            startHour={time.startHour}
            endHour={time.endHour}
            packages={time.packages}
            addPackage={addPackage}
            removePackage={removePackage}
            handleChange={handlePackageChange}
          />
        ))}
    </List>
  );
}

TimesList.propTypes = {
  times: PropTypes.arrayOf(PropTypes.shape(PackageCard.propTypes)),
};

export default TimesList;
