import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RoutesComponent from "./pages/RoutesComponent";
import GlobalStyles from "./styles/GlobaleStyles";
import { HelmetProvider } from "react-helmet-async";
import "react-toastify/dist/ReactToastify.css";
import useSelected from "./store/useSelected";
import { useEffect } from "react";
import useTimes from "./store/useTimes";

function App() {
  useEffect(() => {
    if (window.Cypress) {
      window.Cypress.store = {
        selectedStore: useSelected,
        timesStore: useTimes,
      };
    }
  }, []);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <GlobalStyles />
        <RoutesComponent />
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
