import "styled-components/macro";
import tw, { styled } from "twin.macro";

export const buttonVariants = {
  primary: tw`bg-blue-500 text-white`,
  secondary: tw`bg-white text-blue-500 border border-blue-500 flex-row-reverse`,
};
export const StyledButton = styled.button(({ disabled, color, bgColor }) => [
  tw`
    flex justify-center items-center gap-2 px-4 md:px-8 py-4 text-xl uppercase cursor-pointer rounded-xl
 `,
  ({ variant }) => buttonVariants[variant],
  disabled && tw`opacity-30 cursor-not-allowed`,
  color && `color: ${color};`,
  bgColor && `background-color: ${bgColor};`,
]);

export const StyledIcon = tw.span`
    flex items-center justify-center flex-shrink-0 w-5 h-5 
`;
