import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
//Components
import PackagesList from "../components/TimesList";
import Title from "../components/Title";
//Store
import usePackages from "../store/usePackages";
import { PACKAGES_URL } from "../lib/constants";

export default function FoodBeverage() {
  const { t } = useTranslation();
  const { addPackagesOptions } = usePackages();

  const { id } = useParams();

  //fetch the packages options
  const fetchPackagesOptions = async () => {
    const res = await fetch(PACKAGES_URL + id);
    return res.json();
  };
  const { data } = useQuery(["packages options"], fetchPackagesOptions);

  useEffect(() => {
    if (data) addPackagesOptions(data.results);
  }, [data, addPackagesOptions]);

  return (
    <>
      <div className="w-[90%] flex flex-col gap-12 md:gap-16 mx-auto mb-[150px]">
        <Title title={t("select3")} />
        <PackagesList />
      </div>
    </>
  );
}
