import tw from "twin.macro";

export const Wrapper = tw.div`
    flex flex-col justify-center items-start gap-4 border border-black rounded-2xl p-4
    lg:flex-row lg:gap-6    
`;

export const Header = tw.div`col-span-5 flex flex-col gap-4`;

export const Title = tw.h2`font-medium text-2xl`;

export const DateInfo = tw.div`
   flex flex-col gap-2 capitalize 
   lg:min-w-[17.25rem]
`;

export const Datee = tw.h3`text-[1.25rem]`;

export const Time = tw.p`text-sm`;

export const PackagesWrapper = tw.div`
 w-full flex flex-col justify-center items-center gap-4
`;

export const Add = tw.button`w-full flex justify-center items-center gap-1
     px-4 py-4 capitalize text-[#3396EB] text-2xl leading-7
     border-2 border-dashed border-[#3396EB] rounded-[10px]
     disabled:grayscale
`;
