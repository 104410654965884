import { useMemo, useState } from "react";
//components
import ShapesList from "../ShapesList";
import FilterBar from "../FilterBar";
//styles
import { Shapes, Wrapper } from "./CardsFilter.styles";

function CardsFilter({ meetingRooms }) {
  //handle active category

  const handleActive = (category) => {
    setActive(category);
  };

  //function to get all shapes and how many rooms they have
  const shapes = useMemo(() => {
    const shapes = [];
    meetingRooms.forEach((category) => {
      let index = shapes.findIndex(
        (_category) => _category.shape === category.shape
      );
      if (index === -1) {
        //add the category if doesn't exist
        shapes.push({ shape: category.shape, quantity: 1 });
      } else {
        //increase the quantity if it does exist
        shapes[index].quantity = shapes[index].quantity + 1;
      }
    });

    return shapes;
  }, [meetingRooms]);
  const [active, setActive] = useState(shapes[0]?.shape);

  //filter
  const filterCards = (shape) =>
    meetingRooms.filter((category) => category.shape === shape);
  return (
    <Wrapper>
      <FilterBar
        categories={shapes}
        active={active}
        handleActive={handleActive}
      />
      <Shapes>
        {shapes?.length > 0 &&
          shapes.map(({ shape }) => (
            <ShapesList
              key={shape}
              cards={filterCards(shape)}
              shape={shape}
              setActive={setActive}
              active={active}
            />
          ))}
      </Shapes>
    </Wrapper>
  );
}

export default CardsFilter;
