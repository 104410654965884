const Bin = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 7C16 6.44771 15.5523 6 15 6H5C4.44771 6 4 6.44772 4 7V17C4 17.5523 4.44772 18 5 18H15C15.5523 18 16 17.5523 16 17V7ZM7 10C7 9.44772 7.44772 9 8 9C8.55229 9 9 9.44772 9 10V14C9 14.5523 8.55229 15 8 15C7.44772 15 7 14.5523 7 14V10ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V10ZM8 2C7.44772 2 7 2.44772 7 3C7 3.55228 7.44772 4 8 4H12C12.5523 4 13 3.55228 13 3C13 2.44772 12.5523 2 12 2H8Z"
        fill="#EB4933"
      />
    </svg>
  );
};

export default Bin;
