import create from "zustand";

const updateDates = (array, cardId, dateId, e) => {
  let index = array.findIndex((item) => item._id === cardId);
  if (index > -1 && e.target) {
    const dateIndex = array[index].dates.findIndex(
      (date) => date.id === dateId
    );

    const newDates = array[index].dates;
    newDates[dateIndex] = {
      ...array[index].dates[dateIndex],
      [e.target.name]: e.target.value,
    };
    array[index] = {
      ...array[index],
      dates: newDates,
    };
  }
  return array;
};

const getDate = () => {
  const newDates = {
    id: crypto.randomUUID(),
    startDate: null,
    endDate: null,
  };
  return newDates;
};

const useSelected = create((set, get) => ({
  selected: [],
  isDisabled: false,
  updateSelected: (cards) => set(() => ({ selected: cards })),
  addCard: (card) =>
    set((state) => ({
      selected: [...state.selected, { ...card, dates: [getDate()] }],
    })),
  removeCard: (id) =>
    set((state) => ({
      selected: state.selected.filter((card) => card._id !== id),
    })),
  handleChange: (id, dateId, e) =>
    set((state) => ({ selected: updateDates(state.selected, id, dateId, e) })),
  addDate: (cardId) =>
    set((state) => {
      const index = state.selected.findIndex((card) => card._id === cardId);
      if (index > -1) {
        const newSelected = state.selected;
        newSelected[index].dates = [...newSelected[index].dates, getDate()];
        return {
          selected: newSelected,
        };
      }
    }),
  removeDate: (cardId, dateId) =>
    set((state) => {
      const cardIndex = state.selected.findIndex((card) => card._id === cardId);
      if (cardIndex > -1) {
        const newSelected = state.selected;
        const newDates = state.selected[cardIndex].dates.filter(
          (date) => date.id !== dateId
        );
        newSelected[cardIndex].dates = newDates;
        return {
          selected: newSelected,
        };
      }
    }),
}));

export default useSelected;
