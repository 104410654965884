import React from "react";
import PropTypes from "prop-types";
import { StyledInput } from "./Input.styles";

function Input({ as, name, type, placeholder, value, handleChange, required }) {
  return (
    <label>
      <StyledInput
        as={as}
        isTextarea={as === "textarea"}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e)}
        required={required}
      />
    </label>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default Input;
