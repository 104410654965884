import { createGlobalStyle } from "styled-components";
import tw, { GlobalStyles as BaseStyles } from "twin.macro";

const CustomStyles = createGlobalStyle`
*, html {
  scroll-behavior: smooth !important;
}

html {
  scroll-padding-top: 10rem;
}


 body {
    font-family: 'Poppins', sans-serif;
    
 }

 .swiper-pagination {
   display: flex;
   justify-content: center;
   align-items: center;
   background: rgba(29, 29, 29, 0.8);
   max-width: max-content;
   padding: 8px 16px ;
   box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
   border: 1px solid #414141;
   border-radius: 100px;
   left: 50% !important;
   transform: translateX(-50%)
 }

 .swiper-pagination-bullet {
    width: 10px ;
    height: 10px ;
    background: #9F9F9F ;
    opacity: 1;
 }

 .swiper-pagination-bullet.swiper-pagination-bullet-active {
   width: 14px ;
   height: 14px ;
   background: white;
   opacity: 1;
 }

 //Progressbar
 .swiper.progressbar {
  ${tw`relative w-full  px-6 md:px-12 lg:px-[6.62rem]  mx-auto bg-[#F5F5F5] align-middle`}
  &:before {
    content: "";
    width: 74px;
    background: linear-gradient(
      -270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 79.46%
    );
    left: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
  &:after {
    content: "";
    position: absolute;
    width: 74px;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 79.46%
    );
    right: 0px;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
 }
 .swiper-slide.progressBar {
  display: inline-flex;
  flex:auto;
 }

 .custom_toast_wrapper {
  ${tw`bg-transparent  inline-flex justify-center items-center 
        border border-[#FF6855] border-dashed rounded-xl ml-[25%]`}

 
 }

 .custom_toast_body {
  ${tw`text-[#FF6855] font-medium text-sm transition-none
  bg-[#FFF1F0] flex justify-center items-center p-3 
  border border-[#FF6855]  rounded-xl overflow-auto`}
 }

`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
