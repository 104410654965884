//Components
import Title from "../components/Title";
//store
import useSelected from "../store/useSelected";
import { useTranslation } from "react-i18next";
import DatesList from "../components/DatesList";

export default function Dates() {
  const { t } = useTranslation();
  const { selected, handleChange, addDate, removeDate } = useSelected();

  const getGroupedRooms = () => {
    const rooms = {};
    selected.forEach((room) => {
      const name = room.name.toLowerCase().trim();
      if (rooms.hasOwnProperty(name)) {
        const roomArr = rooms[name];
        rooms[name] = [...roomArr, room];
      } else {
        rooms[name] = [room];
      }
    });
    return rooms;
  };

  return (
    <>
      <div className="w-full lg:w-[94%] mx-auto flex flex-col gap-8 mb-[150px]">
        <Title title={t("select2")} />
        {Object.values(getGroupedRooms())?.length > 0 &&
          Object.values(getGroupedRooms())?.map((group) => (
            <DatesList
              key={group[0].name}
              rooms={group}
              name={group[0].name}
              handleChange={handleChange}
              removeDate={removeDate}
              addDate={addDate}
            />
          ))}
      </div>
    </>
  );
}
