import { useInView } from "react-intersection-observer";
import useSelected from "../../store/useSelected";
import Card from "../Card";
import { List, Shape, Wrapper } from "./ShapesList.styles";

function ShapesList({ cards, shape, setActive }) {
  const { selected, addCard, removeCard } = useSelected();

  //functions to check if a card is checked or not
  const isChecked = (id, array) => {
    let index = array.findIndex((item) => item._id === id);
    if (index === -1) return false;
    return true;
  };

  const handleClick = (id, card) => {
    if (isChecked(id, selected)) {
      removeCard(id);
    } else if (!isChecked(id, selected)) {
      addCard(card);
    }
  };

  //handle scrolling and changing the active shape
  const { ref } = useInView({
    threshold: 0.3,
    onChange: (inView, entry) => {
      const shape = entry?.target?.id;
      if (inView) {
        setActive(shape);
      }
    },
  });

  return (
    <Wrapper id={shape} ref={ref}>
      <Shape>{shape}</Shape>
      <List>
        {cards?.length > 0 &&
          cards.map((item) => (
            <Card
              key={item._id}
              name={item.name}
              adultsCapcity={item.adult_capacity}
              images={item.media}
              dimensions={item.dimensions}
              shape={item.shape}
              checked={isChecked(item._id, selected)}
              handleClick={() => handleClick(item._id, item)}
            />
          ))}
      </List>
    </Wrapper>
  );
}

export default ShapesList;
