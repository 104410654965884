import { Player } from "@lottiefiles/react-lottie-player";
import loading from "../../lotties/loading.json";

export default function Loading() {
  return (
    <>
      <div className="pb-[300px] w-96 h-96 self-center">
        <Player autoplay loop={true} src={loading} />
      </div>
    </>
  );
}
