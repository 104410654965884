const Plus = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 0.5H6.5V6.5H0.5V10.5H6.5V16.5H10.5V10.5H16.5V6.5H10.5V0.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Plus;
