import tw, { styled } from "twin.macro";

export const Wrapper = tw.div`
  w-full flex flex-col justify-center items-start gap-6 p-4
  bg-[#F6F6F6] border border-[#CCCCCC] rounded-[0.6rem]
  lg:flex-row lg:justify-start lg:gap-8 lg:p-8
`;

export const AdditonalInfo = tw.div`
  flex justify-between items-start gap-6 flex-wrap
  lg:justify-center lg:flex-nowrap lg:gap-8
`;

export const PackageSelect = tw.div`
   flex flex-col gap-4 w-full
   lg:gap-6 lg:flex-grow
`;

export const Column = styled.div`
  ${tw`flex flex-col gap-2 `};
  input {
    ${tw`inline-flex justify-between items-stretch  text-center w-40 h-14
    border rounded-lg border-[#666666] focus:outline-none
    lg:w-28`};
    ::-webkit-textfield-decoration-container {
      align-self: stretch !important;
      height: 3.5rem;
    }
    ::-webkit-inner-spin-button {
      opacity: 1;
    }
  }
`;

export const Label = tw.label`
  text-2xl font-medium
`;

export const Pdf = tw.a`
  w-[3.375rem] h-[3.375rem] rounded-lg flex justify-center items-center
  bg-white border border-[#DFDFDF] cursor-pointer
`;

export const Delete = tw.button`
   w-9 h-9 rounded-full flex justify-center items-center
   bg-white  border-[1px] border-[#DDDDDD] self-center
`;
