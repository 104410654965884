import { t } from "i18next";
import useInfo from "../../store/useInfo";
import Input from "../Input";
import { Grid } from "./InputsGrid.styles";

function InputsGrid() {
  const { info, handleChange } = useInfo();

  return (
    <Grid>
      <Input
        type="text"
        placeholder="full Name"
        name="name"
        value={info?.name}
        handleChange={handleChange}
        required
      />
      <Input
        type="text"
        placeholder="company"
        name="company"
        value={info?.company}
        handleChange={handleChange}
      />
      <Input
        type="email"
        placeholder="email"
        name="email"
        value={info?.email}
        handleChange={handleChange}
        required
      />
      <Input
        type="tel"
        placeholder="phone"
        name="phone"
        value={info?.phone}
        handleChange={handleChange}
      />
      <Input
        as="textarea"
        placeholder={t("additionalInfo")}
        name="additionalInfo"
        value={info?.additionalInfo}
        handleChange={handleChange}
      />
    </Grid>
  );
}

export default InputsGrid;
