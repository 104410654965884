import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Plus from "../../icons/Plus";
//styles
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import "swiper/css/pagination";
import {
  Description,
  Image,
  ImagesWrapper,
  Wrapper,
  Add,
  Shape,
} from "./DateCard.styles";
import { useTranslation } from "react-i18next";
import DateItem from "./DateItem";
import { eachDayOfInterval } from "date-fns";

function DateCard({
  handleChange,
  addDate,
  removeDate,
  dates,
  images,
  name,
  cardId,
  shape,
}) {
  const getIsAddDisabled = () => {
    const lastDate = dates[dates.length - 1];
    return lastDate.startDate ? false : true;
  };

  const excludedDates = (currentStart, currentEnd) => {
    const allDays = [];
    let filtreddays;
    dates.forEach(({ startDate, endDate }) => {
      if (startDate && endDate) {
        const days = eachDayOfInterval({ start: startDate, end: endDate });
        allDays.push(...days);
      }
    });

    if (currentStart && currentEnd) {
      const currentInterval = eachDayOfInterval({
        start: currentStart,
        end: currentEnd,
      });

      filtreddays = allDays.filter((day) =>
        currentInterval.some((current) => current === day)
      );
    }

    return filtreddays || allDays;
  };

  const { t } = useTranslation();

  return (
    <Wrapper>
      <ImagesWrapper>
        <Shape>{shape}</Shape>
        <Swiper
          className="w-full h-full"
          slidesPerView="1"
          pagination={images?.length > 1 ? true : false}
          modules={[Pagination]}
        >
          {images?.length &&
            images.map((image) => (
              <SwiperSlide key={image.filename}>
                <Image src={image.location} alt={name} />
              </SwiperSlide>
            ))}
        </Swiper>
      </ImagesWrapper>
      <Description>
        {dates?.map((date, index) => (
          <DateItem
            key={date.id}
            excludedDates={excludedDates(date?.startDate, date?.endDate)}
            cardId={cardId}
            dateId={date.id}
            shape={shape}
            isOpen={dates.length - 1 === index}
            name={name}
            handleChange={handleChange}
            removeDate={removeDate}
            startDate={date?.startDate}
            endDate={date?.endDate}
            hasDelete={dates.length > 1}
          />
        ))}
        <Add
          type="button"
          onClick={() => addDate(cardId)}
          disabled={getIsAddDisabled()}
        >
          <Plus />
          {t("addNewDate")}
        </Add>
      </Description>
    </Wrapper>
  );
}

export default DateCard;
